import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import Kiwok from './projects/kiwok'

export function ProjectKiwokComponent() {
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">KIWOKO HOSPITAL</div>
                <div className="info_wrapper">
                    <div className="info">
                        {/* TODO: Media break for mobile devices */}
                        <span className="infoWhere">WO:</span>
                        <span className="infoWhat">KIWOKO, UGANDA</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WAS:</span>
                        <span className="infoWhat">BERUFSSCHULE</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WANN:</span>
                        <span className="infoWhat">2021 - </span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">FÜR WEN:</span>
                        <span className="infoWhat">Kiwoko Hospital</span>
                    </div>
                </div>
            </div>
            <hr />
            <Kiwok />
            <hr />
        </TabsContainer>
    )
}
