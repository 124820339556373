import { useCallback, useRef } from 'react'
import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import img1 from '../components/projects/donate/01.jpg'
import img2 from '../components/projects/donate/02.jpg'
import img3 from '../components/projects/donate/03.jpg'
import { isMobile } from 'react-device-detect'
import { BsArrowRight } from 'react-icons/bs'

export function DonateComponent() {
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }

    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">SPENDEN</div>
            </div>
            <hr />
            <div
                className="scroll-container"
                ref={scrollContainerRef}
                onWheel={handleScroll}
            >
                <div
                    className="scroll-content"
                    style={{ height: isMobile ? '60vh' : '65vh' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '2rem',
                            justifyContent: 'center',
                            marginLeft: '2rem',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '2rem',
                                fontWeight: 'bold',
                            }}
                        >
                            <span className="scroll">SCROLL</span>{' '}
                            <BsArrowRight />
                        </div>
                    </div>
                    <div className="txtimg">
                        <div className="projectText">
                            Wir freuen uns über jeden Beitrag zur Finanzierung
                            unserer Arbeit. Sie spenden direkt für anfallende
                            Projektkosten. Bitte beachten Sie, dass wir aus
                            Sicherheitsgründen Ihre IP-Adresse temporär
                            speichern. Natürlich können Spenden einfach direkt
                            auf das socialarchitecture Konto überwiesen werden.
                            (Wenn Sie im Betreff Ihre Adresse mit angeben,
                            erhalten Sie automatisch eine Spendenquittung)
                            <br />
                            <div>
                                <br />
                                Inhaber: socialarchitecture e.V.
                                <br />
                                Kto-Nr: 130 656 2000
                                <br />
                                IBAN: DE49 4306 0967 1306 5620 00
                                <br />
                                BIC: GENODEM1GLS
                                <br />
                                GLS Gemeinschaftsbank eG.
                            </div>
                        </div>
                        <img
                            src={img1}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        />
                    </div>
                    <div className="txtimg">
                        <div className="projectText">
                            <div style={{ fontWeight: 'bold' }}>
                                Spenden bis 300,- €
                            </div>
                            Statt einer Zuwendungsbestätigung genügt der
                            Bareinzahlungsbeleg oder die Buchungsbestätigung
                            eines Kreditinstituts, wenn die Zuwendung 300 Euro
                            nicht übersteigt und der Empfänger eine
                            Körperschaft, Personenvereinigung oder
                            Vermögensmasse im Sinne des § 5 Absatz 1 Nummer 9
                            des Körperschaftsteuergesetzes ist, wenn der
                            steuerbegünstigte Zweck, für den die Zuwendung
                            verwendet wird, und die Angaben über die
                            Freistellung des Empfängers von der
                            Körperschaftsteuer auf einem von ihm hergestellten
                            Beleg aufgedruckt sind und darauf angegeben ist, ob
                            es sich bei der Zuwendung um eine Spende oder einen
                            Mitgliedsbeitrag handelt (…)
                        </div>
                        <div className="projectText">
                            Aus der Buchungsbestätigung müssen der Name und die
                            Kontonummer oder ein sonstiges
                            Identifizierungsmerkmal des Auftraggebers und des
                            Empfängers, der Betrag, der Buchungstag sowie die
                            tatsächliche Durchführung der Zahlung ersichtlich
                            sein.
                        </div>
                        <img
                            src={img2}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        />
                    </div>
                    <div className="txtimg">
                        <div className="projectText">
                            <div style={{ fontWeight: 'bold' }}>
                                Spenden über 300,- €
                            </div>
                            Bei Spendenbeträgen über 300,- € erhalten Sie von
                            uns unaufgefordert eine Spendenbescheinigung für das
                            Finanzamt.  Falls Sie eine Spendenbescheinigung
                            wünschen, vergessen Sie bitte nicht Ihre Anschrift
                            mit anzugeben.
                        </div>
                        <img
                            src={img3}
                            alt="alt"
                            height={widthByDevice()}
                            style={{ marginLeft: '2rem' }}
                        />
                    </div>
                    <div style={{ color: 'white' }}>hidden text</div>
                </div>
            </div>
            <hr />
        </TabsContainer>
    )
}
