import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import Ahyone from './projects/ahyone'

export function ProjectAhyoneComponent() {
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">AH YONE OO</div>
                <div className="info_wrapper">
                    <div className="info">
                        {/* TODO: Media break for mobile devices */}
                        <span className="infoWhere">WO:</span>
                        <span className="infoWhat">MAE SOT, THAILAND</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WAS:</span>
                        <span className="infoWhat">SCHULE</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WANN:</span>
                        <span className="infoWhat">2023 - </span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">FÜR WEN:</span>
                        <span className="infoWhat">AH YONE OO LEARNING CENTER</span>
                    </div>
                </div>
            </div>
            <hr />
            <Ahyone />
            <hr />
        </TabsContainer>
    )
}
