import { useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import img1 from './imgwas/01.jpg'
import img2 from './imgwas/02.jpg'
import img3 from './imgwas/03.jpeg'
import img4 from './imgwas/04.jpg'
import img5 from './imgwas/05.jpg'
import img6 from './imgwas/06.jpg'
import img7 from './imgwas/07.jpg'
import img8 from './imgwas/08.jpg'
import img9 from './imgwas/09.jpg'
import img10 from './imgwas/10.jpg'
import img11 from './imgwas/11.jpg'
import { BsArrowRight } from 'react-icons/bs'

export default function WakeUpSchool() {
    // script.ts
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    return (
        <div
            className="scroll-container"
            ref={scrollContainerRef}
            onWheel={handleScroll}
        >
            <div
                className="scroll-content"
                style={{ height: isMobile ? '59vh' : '65vh' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginLeft: '2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className="scroll">SCROLL</span> <BsArrowRight />
                    </div>
                </div>
                <div className="txtimg" style={{ marginLeft: '2rem' }}>
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img2}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div className="projectText">
                        Nakivale ist eine UNHCR Flüchtlingssiedlung im Süden
                        Ugandas. Sie liegt auf rund 1.500m Höhe, ist ca. 180km2
                        groß und wurde 1958 gegründet – damit ist sie die
                        Älteste Afrikas. Anfang 2023 lebten hier rund 167.000
                        Menschen, der Großteil stammt aus dem Kongo. Nach der
                        Registrierung als Flüchtling bekommen die Menschen hier
                        ein Stück Land zugewiesen. Dort können sie sich ihr
                        neues Zuhause bauen, die meisten Gebäude bestehen aus
                        Lehmziegeln, Eukalyptusholz und Wellblech. So entstand
                        in den letzten Jahrzehnten eine informell gewachsene
                        Stadt mit Straßen, Plätzen und Wohngebieten. Es gibt
                        kein fließendes Wasser, keine Kanalisation und auch nur
                        vereinzelt Elektrizität. Trinkwasser müssen sich die
                        Menschen meist aus weit entfernten Brunnen besorgen.
                    </div>
                    <div className="projectText">
                        Die Arbeitslosigkeit ist unfassbar groß und da die
                        finanzielle Unterstützung von außerhalb bei weitem nicht
                        ausreichend ist, bleibt den meisten nicht anderes übrig
                        als sich selbst zu versorgen. Umso wichtiger sind
                        Projekte, bei denen die Menschen hier Hoffnung auf ein
                        besseres Leben finden können. Eins davon ist die 2016
                        von Mugisha Namwangwa gegründete WAKE-UP-SCHOOL.
                        <br />
                        Amtssprache in Uganda ist Englisch – in den Ländern aus
                        denen die meisten in Nakivale lebenden Flüchtlinge
                        kommen wird französisch gesprochen. Diese Sprachbarriere
                        macht es den Flüchtlingen zusätzlich schwer eine
                        Arbeitsstelle in Uganda zu finden, obwohl sie
                        grundsätzlich über eine Arbeitserlaubnis verfügen.
                        Diesem Problem hat sich Mugisha mit seiner Organisation
                        „success roots“ angenommen.
                    </div>
                    <div className="projectText">
                        In seiner Schule sollten ursprünglich Erwachsene die
                        englische Sprache lernen, schnell wurde die Schule aber
                        auch für Kinder geöffnet. 2020, als wir unser Engagement
                        als Privatpersonen für die WAKE-UP-SCHOOL starteten,
                        gingen hier bereits knapp 300 Kinder zur Schule und
                        wurden im Sinne des Ugandische Schulsystems
                        unterrichtet. Die Räumlichkeiten hingegen entsprachen
                        bei weitem nicht den Mindeststandards. Die Wände waren
                        einsturzgefährdet, die Dächer undicht und die Räume
                        klein, dunkel und stickig.
                    </div>
                </div>
                <div className="txtimg">
                    <img
                        src={img3}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img4}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div className="projectText">
                        2020 kam der erste Kontakt zustande, per Facebook fragte
                        Mugisha um Unterstützung beim Neubau der Schule. Schnell
                        wurde klar, dass der junge Mann aus Nakivale es ernst
                        meint und überaus organisiert und engagiert war. Er
                        lieferte schnell alle notwendigen Informationen und so
                        konnte die Planung beginnen. Schlussendlich
                        verständigten wir uns auf eine schrittweise
                        Realisierung, an dessen Ende sich 13-14 Klassenräume um
                        zwei Höfe orientieren sollen. Der größere der beiden
                        Höfe wird zusätzlich mit einem schattenspendenden
                        Korridor flankiert. Im kleineren Schulhof wurde 2022
                        außerdem ein Brunnen gebohrt, über den sich nicht nur
                        die Schüler und Lehrer, sondern auch die sonstigen
                        Bewohner der Nachbarschaft mit Trinkwasser versorgen
                        können. Im gleichen Jahr ergab sich die Möglichkeit ein
                        Nachbargrundstück zu erwerben.
                    </div>
                    <div className="projectText">
                        Hier entstand bisher ein Sanitärhaus mit
                        Zweikammersystem und eine kleine Apotheke. In Zukunft
                        soll hier ein Multifunktionsraum entstehen, den die
                        Schule als Kantine oder für Veranstaltungen nutzen kann.
                        <br />
                        Gemäß unserer Philosophie beim Realisieren solcher
                        Projekte, verwenden wir beim Bau der Klassen- und
                        Nebenräume ausschließlich vor Ort verfügbare Ressourcen
                        und Baumaterialien. Auf Beton und Zement versuchen wir
                        weitestgehend zu verzichten. So bestehen die Fundamente
                        aus Steinen aus einem lokalen Steinbruch, die Wände aus
                        sonnengetrockneten Lehmziegeln (Adobe Bricks) und das
                        Dachtragwerk, die Fenster- und Türen sowie die
                        Schultische aus lokal verfügbarem Holz.
                    </div>
                    <div className="projectText">
                        Indem die Wände nicht bis unter die Dachhaut reichen,
                        fällt ausreichend Tageslicht in die Innenräume und die
                        Schüler können bei ausreichen Frischluft und angenehmen
                        Temperaturen unterrichtet werden. Die großzügig
                        auskragenden Dachflächen und der umlaufende Korridor
                        schützen einerseits die Nutzer vor Sonne und Regen,
                        sorgen gleichzeitig aber auch dafür, dass die Wände auch
                        bei Starkregen trocken und somit standsicher bleiben.
                        <br />
                        <br />
                        An dieser Stelle möchten wir uns nochmal bei allen
                        Menschen bedanken, die das Projekt entweder finanziell
                        unterstützt oder auch tatkräftig vor Ort mitgeholfen
                        haben. Besonderer Dank geht an die beiden Großspender
                        Firma{' '}
                        <a
                            style={{
                                color: 'black',
                            }}
                            href="https://www.claytec.de/de/clayblog/der-bau-der-wake-up-school-in-uganda-jede-spende-hilft_n4079"
                        >
                            ClayTec
                        </a>{' '}
                        aus Viersen und den Klimaarchitekten Florian{' '}
                        <a
                            style={{
                                color: 'black',
                            }}
                            href="https://betzler.net"
                        >
                            Betzler
                        </a>{' '}
                        aus Hamburg.
                    </div>
                    <img
                        src={img5}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img6}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img7}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />

                    <img
                        src={img8}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img9}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img10}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img11}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div style={{ color: 'white' }}>hidden text</div>
            </div>
        </div>
    )
}
