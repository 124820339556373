import { useCallback, useEffect, useRef } from 'react'
import img1 from './imgah/01.jpg'
import img2 from './imgah/02.jpg'
import img3 from './imgah/03.jpg'
import img4 from './imgah/04.jpg'
import img5 from './imgah/05.jpg'
import img6 from './imgah/06.jpg'
import img7 from './imgah/07.jpg'
import img8 from './imgah/08.jpg'
import { isMobile } from 'react-device-detect'
import { BsArrowRight } from 'react-icons/bs'

export default function Ahyone() {
    useEffect(() => {
        console.log('ismobile', isMobile)
    })
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    return (
        <div
            className="scroll-container"
            ref={scrollContainerRef}
            onWheel={handleScroll}
        >
            <div
                className="scroll-content"
                style={{ height: isMobile ? '59vh' : '65vh' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginLeft: '2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className="scroll">SCROLL</span> <BsArrowRight />
                    </div>
                </div>
                <div className="txtimg">
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img2}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div className="projectText">
                        Innerhalb von fünf Wochen haben wir den Bau von zwei
                        neuen Klassenzimmern, einem Sitzungsraum und einem
                        kleinen Galerieraum abgeschlossen. Wir haben Gruppen von
                        Architekturstudenten der Assumption University (AU) und
                        des King Monkhut's Institute of Technology Ladkrabang
                        (KMITL) in Bangkok sowie Studenten der Harrow
                        International School eingeladen, an Workshops mit der
                        örtlichen Gemeinschaft teilzunehmen. In diesen Workshops
                        demonstrierten wir lokale Bautechniken mit Bambus,
                        Eukalyptus und Adobe-Ziegeln (Baan Din). Die
                        Adobe-Ziegel werden in Zusammenarbeit mit einheimischen
                        Schülern, Universitätsstudenten und Freiwilligen unter
                        Verwendung von lokal verfügbarer Erde in Kombination mit
                        Wasser und Reishülsen als Fasermaterial hergestellt. Die
                        Ziegel werden über einen Zeitraum von 2-3 Tagen in der
                        Sonne getrocknet, bis sie baureif sind.
                    </div>
                    <div className="projectText">
                        Zusammen mit lokalen Arbeitern, die den Bau überwachen,
                        werden wir mit dem Bau von Klassenzimmerwänden aus den
                        selbst hergestellten Ziegeln beginnen. Dieser
                        partizipatorische Ansatz wird das Gefühl der
                        Eigenverantwortung bei den Schülern stärken, die die
                        Klassenzimmer täglich nutzen werden. Für die
                        Dachkonstruktion werden wir Holz aus zweiter Hand
                        wiederverwenden. Generell ist es wichtig, die Menge an
                        Beton auf ein Minimum zu reduzieren und auf
                        umweltfreundlichere und langlebigere Lösungen zu setzen.
                        Wir haben die Aufgabe innerhalb des kalkulierten
                        Zeitrahmens und des Budgets von 7000$ abgeschlossen.
                    </div>
                    <div className="projectText">
                        Das Projekt fügt sich nahtlos in die Umgebung ein, da es
                        zwischen schattenspendenden Tamarindenbäumen und einem
                        atemberaubenden Ausblick auf die burmesischen Grenzberge
                        liegt. Die Mauern aus Adobe-Ziegeln stellen durch die
                        Verwendung von lokalem Boden eine Verbindung zum
                        Standort her. Reishülsen, ein übliches Abfallmaterial,
                        werden als Faserzusatz für die Ziegelmischung verwendet.
                        Ab und zu hört man Mörserfeuer in der Landschaft, was
                        uns an die aktuellen Kämpfe und die komplexe politische
                        Situation in Birma erinnert.
                    </div>
                    <div className="projectText">
                        Um den CO2-Ausstoß zu verringern, haben wir darauf
                        geachtet, lokal verfügbare Materialien zu verwenden. Die
                        Lehmziegel für die Wände wurden mit Erde aus dem
                        angrenzenden Land selbst hergestellt, während das Holz
                        aus zweiter Hand im nahe gelegenen Holzgeschäft gekauft
                        wurde. Lediglich für die Fundamente mussten wir
                        Stahlbeton verwenden, um eine stabile Basis für die
                        Lehmwände und die leichte Dachkonstruktion aus
                        gebrauchtem Holz zu schaffen. Darüber hinaus sind alle
                        Klassenzimmer so konstruiert, dass aufgrund der
                        wärmeabsorbierenden Lehmwände, der Lage im Schatten der
                        Bäume und der daraus resultierenden natürlichen
                        Belüftung keine weiteren Kühlmaßnahmen erforderlich
                        sind.
                    </div>
                    <div className="projectText">
                        Bevor wir mit dem Entwurfsprozess begannen, haben wir
                        uns dafür eingesetzt, dass das Projekt den Bedürfnissen
                        der Schulgemeinschaft entspricht und auf eine
                        langfristige Nachhaltigkeit ausgerichtet ist. Wir
                        stellten Arbeiter mit unterschiedlichen Fähigkeiten aus
                        der lokalen Gemeinschaft ein. Während des Bauprozesses
                        tauschten wir unser Wissen über Bautechniken und
                        örtliche Detaillösungen aus, was zu einem besseren
                        Know-how für alle Beteiligten führte. Darüber hinaus
                        wurde der Bauherr in den Entwurfsprozess einbezogen, und
                        die Studenten beteiligten sich an verschiedenen
                        Aktivitäten, um ein gemeinsames Gefühl der Verantwortung
                        zu schaffen. Wir haben auch Studenten der Universität
                        Bangkok eingeladen, an Lehmbau-Workshops teilzunehmen
                        und Lehmziegel herzustellen, Lehmwände zu bauen oder zu
                        verputzen.
                    </div>
                    <div className="projectText">
                        Bei diesem Low-Budget Design/Build-Projekt haben wir
                        immer die Unterstützung der Schulgemeinschaft im Auge,
                        soweit dies möglich ist. Die vor Ort hergestellten
                        Lehmziegel verschaffen den örtlichen Arbeitskräften ein
                        Einkommen und sorgen so für die Unterstützung und den
                        Umlauf von Geldern innerhalb der Gemeinde. Ein
                        partizipatorischer Planungs- und Bauansatz schafft ein
                        Gefühl der Eigenverantwortung. Die vor Ort erprobten
                        Bautechniken ermöglichen eine einfache Instandhaltung
                        des Projekts.
                    </div>
                    <img
                        src={img3}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img4}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img5}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img6}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img7}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img8}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div style={{ color: 'white' }}>hidden text</div>
            </div>
        </div>
    )
}
