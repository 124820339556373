import React, { useCallback } from 'react'
import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import img1 from './projects/join/Bild_01.jpg'
import pdf from './projects/join/apply.pdf'
import { isMobile } from 'react-device-detect'

export function TakepartComponent() {
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">MITMACHEN</div>
            </div>
            <hr />
            <div className="projektContent">
                <div className="txtimg">
                    <div className="projectText">
                        Willst du Mitglied und Teil unserer
                        socialarchitecture-Familie sein? Als Mitglied
                        unterstützt du uns durch gelegentliche Mitarbeit und
                        gestaltest die Arbeit unseres Vereins mit. Dein
                        Mitgliedsbeitrag <br /> dient zur Finanzierung unserer
                        Verwaltung. Das ist wichtig, denn nur so können die
                        Spenden zu 100% in die Projekte fließen.
                        <br />
                        <br />
                        Fülle das folgende Antragsformular aus und überweise den
                        Mitgliedsbeitrag für das laufende Kalenderjahr auf
                        unser Verwaltungskonto. Wenn du mit einer Zusatzspende
                        unsere Arbeit unterstützen magst, sind wir dir sehr
                        dankbar.
                        <br />
                        <br />
                        <a
                            style={{
                                color: 'black',
                                fontSize: '16pt',
                                fontWeight: 'bold',
                            }}
                            href={pdf}
                        >
                            PDF Downloaden
                        </a>
                    </div>
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    ></img>
                </div>
            </div>
            <hr />
        </TabsContainer>
    )
}
