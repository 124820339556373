import { useCallback, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import img1 from './imgkw/01.jpg'
import img2 from './imgkw/02.jpg'
import img3 from './imgkw/03.jpg'
import img4 from './imgkw/04.jpg'
import img5 from './imgkw/05.jpg'
import img6 from './imgkw/06.jpg'
import img7 from './imgkw/07.jpg'
import img8 from './imgkw/08.jpg'
import { BsArrowRight } from 'react-icons/bs'

export default function Kiwok() {
    const widthByDevice = useCallback(() => {
        if (isMobile) {
            return 350
        }
        return 450
    }, [])
    window.addEventListener('DOMContentLoaded', () => {
        const scrollContainer = document.querySelector(
            '.scroll-container'
        ) as HTMLDivElement

        scrollContainer.addEventListener('wheel', (event) => {
            event.preventDefault()
            scrollContainer.scrollLeft += event.deltaY
        })
    })
    const scrollContainerRef = useRef<HTMLDivElement | null>(null)

    const handleScroll = (event: React.WheelEvent<HTMLDivElement>) => {
        if (scrollContainerRef.current) {
            event.preventDefault()
            scrollContainerRef.current.scrollLeft += event.deltaY
        }
    }
    return (
        <div
            className="scroll-container"
            ref={scrollContainerRef}
            onWheel={handleScroll}
        >
            <div
                className="scroll-content"
                style={{ height: isMobile ? '59vh' : '65vh' }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2rem',
                        justifyContent: 'center',
                        marginLeft: '2rem',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            fontWeight: 'bold',
                        }}
                    >
                        <span className="scroll">SCROLL</span> <BsArrowRight />
                    </div>
                </div>
                <div className="txtimg">
                    <img
                        src={img1}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img2}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div className="projectText">
                        Kiwoko ist eine Kleinstadt im Nakaseke District in
                        Uganda, ca. 79 km nördlich der Hauptstadt Kampala
                        gelegen. Wie auch im Rest Ugandas ist das
                        Durchschnittsalter der ca. 11.000 Einwohner durch den
                        Kinderreichtum sehr jung. Ihren Lebensunterhalt
                        verdienen die meisten Familien mit der Bewirtschaftung
                        von Feldern und dem Verkauf selbstgemachter Güter auf
                        dem örtlichen Markt oder in kleinen Läden.
                    </div>
                    <div className="projectText">
                        Beim Kiwoko Hospital handelt es sich um ein privates
                        Krankenhaus, dass sich unter anderem aus
                        Behandlungskosten und Ausbildungsgebühren finanziert. Es
                        wurde 1991 eröffnet und zählt mit 400 Mitarbeitern
                        mittlerweile zu den größten Arbeitgebern der Region. Es
                        verfügt über 200 Betten und jährlich werden hier im
                        Krankenhaus ca. 1800 Operationen durchgeführt. Das
                        Krankenhaus verfügt über diverse Abteilungen, darunter
                        befinden sich u.a. eine Gynäkologie, Chirurgie, Innere
                        Medizin, ein Labor und eine Radiologie. Das Krankenhaus
                        bildet auch selber den medizinischen Nachwuchs aus. Der
                        größte Bedarf besteht bei allen Abteilungen rund um das
                        Thema Geburt. Um diesen Bereich in Zukunft besser
                        betreuen zu können, soll das Krankenhaus um ein neues
                        Schulungszentrum erweitert werden.
                    </div>
                    <div className="projectText">
                        Das Schulungszentrum soll Klassenräume für rund 750
                        Studenten umfassen, sowie ein Computerlabor, eine
                        Bibliothek und diverse Sanitär- und Lagerräume. In einem
                        nächsten Schritt sollen - ebenfalls auf dem
                        Krankenhausgelände - auch neue Studentenunterkünfte für
                        einen Großteil der Studenten errichtet werden. Der
                        Masterplan sieht analog zur bestehenden Bebauung
                        eingeschossige Baukörper mit geneigtem Dach vor, die
                        sich um drei begrünte Lernhöfe orientieren. Alle Gebäude
                        sind über Pergolen miteinander verbunden und Pavilions
                        in den Höfen laden auch nach Unterrichtsschluss zum
                        gemeinsamen Lernen und Arbeiten an der frischen Luft
                        ein.
                    </div>
                    <img
                        src={img3}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div className="txtimg">
                    <img
                        src={img4}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img5}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                </div>
                <div className="txtimg">
                    <img
                        src={img6}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img7}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <img
                        src={img8}
                        alt="alt"
                        height={widthByDevice()}
                        style={{ marginLeft: '2rem' }}
                    />
                    <div style={{ color: 'white' }}>hidden text</div>
                </div>
            </div>
        </div>
    )
}
