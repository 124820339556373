import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import img6 from './projects/contact/Bild 01.jpg'
import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';

export function ContactComponent() {

    const widthByDevice = useCallback(() => {
        if(isMobile) {
            return 350; 
        }
        return 450;
    }, [])
    
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">KONTAKT</div>
            </div>
            <hr />
            <div className="projektContent">
                <div className="txtimg" style={{ marginLeft: '2rem' }}>
                    <div className="projectTextKontakt">
                        Wir freuen uns von dir zu hören.
                        <br />
                        <br />
                        socialarchitecture e.V.
                        <br />
                        c/o studio. - Bürogemeinschaft für Architektur
                        <br />
                        Lübecker Straße 32
                        <br />
                        44135 Dortmund
                        <br />
                        info@social-arc.org
                    </div>
                    <img src={img6} alt="alt" height={widthByDevice()} />
                </div>
            </div>
            <hr />
        </TabsContainer>
    )
}
