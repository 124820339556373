import '../styles/tabs.css'
import { Outlet, Link } from 'react-router-dom'

export function TabsComponent({ tabs }: { tabs: Array<string> }) {
    return (
        <div className="tabs">
            {tabs.map((tab) => (
                <div key={tab}>
                    <Link
                        className='links'
                        to={`/${tab === 'PROJEKTE' ? '' : tab}`}
                    >
                        {tab}
                    </Link>
                </div>
            ))}
            <Outlet />
        </div>
    )
}
