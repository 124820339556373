import { TabsContainer } from './tabsContainer'
import '../styles/projects.css'
import WakeUpSchool from './projects/wakeupschool'

export function ProjectComponent() {
    return (
        <TabsContainer>
            <hr />
            <div className="projectContainer">
                <div className="wakeup">WAKE-UP-SCHOOL</div>
                <div className="info_wrapper">
                    <div className="info">
                        {/* TODO: Media break for mobile devices */}
                        <span className="infoWhere">WO:</span>
                        <span className="infoWhat">
                            Nakivale, Uganda - UNHCR Flüchtlingssiedlung
                        </span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WAS:</span>
                        <span className="infoWhat">Schulbau</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">WANN:</span>
                        <span className="infoWhat">2021</span>
                    </div>
                    <div className="info">
                        <span className="infoWhere">FÜR WEN:</span>
                        <span className="infoWhat">
                            success roots, Mugisha Namwangwa
                        </span>
                    </div>
                </div>
            </div>
            <hr />
            <WakeUpSchool />
            <hr />
        </TabsContainer>
    )
}
