import React from 'react'
import { TabsComponent } from './tabs'

export function TabsContainer({ children }: { children: React.ReactNode }) {
    return (
        <>
            <TabsComponent tabs={['PROJEKTE', 'ÜBER UNS', 'SPENDEN']} />
            {children}
            <div className="tabs--bottom">
                <TabsComponent tabs={['MITMACHEN', 'KONTAKT', 'IMPRESSUM']} />
            </div>
        </>
    )
}
